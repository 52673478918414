import { MarkerProps } from "@react-google-maps/api";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React from "react";

const containerStyle = {
    width: "100%",
    height: "100%",
};

const center = {
    lat: 40.507351,
    lng: -0.127758,
};

const mapOptions = {
    zoom: 3,
    scrollwheel: true,
    center: center,
    disableDefaultUI: true,
    styles: [
        {
            elementType: "geometry",
            stylers: [{ color: "#f5f5f5" }],
        },
        {
            elementType: "labels.icon",
            stylers: [{ visibility: "on" }, { hue: "0" }],
        },
        {
            elementType: "labels.text.fill",
            stylers: [{ color: "#000000" }],
        },
        {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [{ color: "#de785d" }],
        },
        {
            featureType: "administrative.land_parcel",
            stylers: [{ visibility: "off" }],
        },
        {
            featureType: "administrative.neighborhood",
            stylers: [{ visibility: "off" }],
        },
        {
            featureType: "landscape",
            elementType: "all",
            stylers: [{ color: "#de785d" }],
        },
        {
            featureType: "poi",
            elementType: "all",
            stylers: [{ visibility: "off" }],
        },
        {
            featureType: "road",
            stylers: [{ saturation: -100 }, { lightness: 45 }],
        },
        {
            featureType: "road.highway",
            elementType: "all",
            stylers: [{ visibility: "simplified" }],
        },
        {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
        },
        {
            featureType: "transit",
            elementType: "all",
            stylers: [{ visibility: "off" }],
        },
        {
            featureType: "water",
            elementType: "all",
            stylers: [{ color: "#dbdbdb" }, { visibility: "on" }],
        },
    ],
};

const markerCoords = [
    { lat: 51.503411, lng: -0.126662 },
    { lat: 53.349805, lng: -6.26031 },
    { lat: 48.856614, lng: 2.352222 },
    { lat: 40.712775, lng: -74.005973 },
    { lat: 35.689487, lng: 139.691706 },
];

const Map = ({ setIsInsideMap }: { setIsInsideMap: any }) => {
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyBRKnDADbl7KlWL7PGA2sk8utTU3susdx8",
    });

    const handleMouseEnter = () => {
        setIsInsideMap(true);
    };

    const handleMouseLeave = () => {
        setIsInsideMap(false);
    };

    return isLoaded ? (
        <div
            id="MapContainer"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="fill-parent"
        >
            <GoogleMap mapContainerStyle={containerStyle} options={mapOptions}>
                {markerCoords.map((coords, index) => (
                    <Marker
                        key={index}
                        position={coords}
                        options={
                            {
                                strokeColor: "#000000",
                            } as unknown as Readonly<MarkerProps>
                        }
                    />
                ))}
            </GoogleMap>
        </div>
    ) : (
        <></>
    );
};

export default React.memo(Map);
