import classNames from "classnames";
import Map from "../Map";
import { SOCIAL_MEDIA } from "../../Constants";

const FooterSection = ({
    selectedSection,
    setIsInsideMap,
}: {
    selectedSection: number;
    setIsInsideMap: any;
}) => {
    return (
        <div
            id="FooterSection"
            className={classNames("animatesec cont sec7", {
                active: selectedSection === 7,
                disabled: selectedSection !== 7,
            })}
            data-secname="CONTACT"
        >
            <div id="InfoSection" className="col two">
                <div id="InfoInner">
                    <div id="SocialMedia">
                        {SOCIAL_MEDIA.map(({ id, imageSrc, link, title }) => (
                            <a
                                className="box"
                                href={link}
                                id={id}
                                key={title}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={imageSrc} alt={title} />
                            </a>
                        ))}
                    </div>
                    <h2>
                        <a href="tel:9294372223">+1.929.437.2223</a> |{" "}
                        <a href="mailto:SUPPORT@PUREMARKETING.AI">
                            SUPPORT@PUREMARKETING.AI
                        </a>
                        <br />
                        <span className="two">
                            MONTCLAIR . TORONTO . SINGAPORE . DUBAI . LONDON
                        </span>
                    </h2>
                </div>
            </div>
            <div id="ContactSection" className="col one">
                <Map setIsInsideMap={setIsInsideMap} />
                <div className="overlay"></div>
            </div>
        </div>
    );
};

export default FooterSection;
