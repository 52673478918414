import classNames from "classnames";

const PreloadNavigate = ({ preloadFinish }: { preloadFinish: boolean }) => {
    return (
        <div
            id="PreloadNavigate"
            className={classNames({ hideLoader: preloadFinish })}
        ></div>
    );
};

export default PreloadNavigate;
