export const SECTIONS_NAME: Record<number, string> = {
    1: "WELCOME",
    2: "THE HOW",
    3: "THE WHY",
    4: "THE TEAM",
    5: "THE WHAT",
    6: "PMG CREDIT",
    7: "CONTACT",
};

export const SOCIAL_MEDIA = [
    {
        id: "One",
        imageSrc: "Assets/Icons/Social/facebook.png",
        link: "https://www.facebook.com/PureMarketingAI",
        title: "facebook",
    },
    {
        id: "Two",
        link: "https://twitter.com/PureMarketingAI",
        imageSrc: "Assets/Icons/Social/twitter.png",
        title: "twitter",
    },
    {
        id: "Three",
        link: "https://www.instagram.com/puremarketing.ai/",
        imageSrc: "Assets/Icons/Social/instagram.png",
        title: "instagram",
    },
    {
        id: "Four",
        link: "https://www.tiktok.com/@puremarketing.ai",
        imageSrc: "Assets/Icons/Social/tiktok.png",
        title: "tiktok",
    },
];

export const HOW_CONTENTS_PART_1 = [
    {
        title: ["UNLOCKING", "FASCINATION"],
        points: [
            {
                point: "The Most Fascinating Brands Communicate in 1 of the 7 Brand Languages,",
                subText: "does your marketing team know what they are?",
            },
            {
                point: "Successful Brands use up to 3 brand languages with 1 primary language,",
                subText: "does your team know which one to communicate with?",
            },
            {
                point: "World Class Brands Differentiate Themselves by their language,",
                subText: "do you?",
            },
        ],
    },
    {
        title: ["UNLEASHING", "FASCINATION"],
        points: [
            {
                point: "Using a singular & specific brand language communicates",
                subText: "powerfully relevant ideas to your audience.",
            },
            {
                point: "Communicating powerfully relevant ideas with a primary brand language",
                subText: "enables people to align with your mission.",
            },
            {
                point: "Aligning your mission with the right audience creates massive action",
                subText: "a movement!",
            },
        ],
    },
];

export const HOW_CONTENTS_PART_2 = [
    {
        title: ["UNLOCKING", "TRANSCENDENCE"],
        points: [
            {
                point: "Traditionalists play the competition game; Transcenders change the game",
                subText: "which are you?",
            },
            {
                point: "Traditionalists ask customers what they want (Reactive) \n Transcenders show customers what they need (Proactive);",
                subText: "are you asking or showing?",
            },
            {
                point: "Transcenders lead with a purposeful campaign and follow with the brand",
                subText: "do you?",
            },
        ],
    },
    {
        title: ["UNLEASHING", "TRANSCENDENCE"],
        points: [
            {
                point: "Changing the game",
                subText: "eliminates your competition.",
            },
            {
                point: "Showing customers what they need",
                subText: "future-proofs your business.",
            },
            {
                point: "Leading with purpose turns consumers into",
                subText: "fanatical brand ambassadors.",
            },
        ],
    },
];

export const HOW_CONTENTS_PART_3 = [
    {
        title: ["UNLOCKING", "VIRALITY"],
        points: [
            {
                point: "Viral Brands turn customers into storytellers",
                subText: "what story are your customers telling?",
            },
            {
                point: "Timeless Brands create experiences that make people feel fascinating;",
                subText:
                    "does talking about your brand make people feel fascinating?",
            },
            {
                point: "Everlasting Brands care about their communities, creating emotional bonds;",
                subText: "how are people talking about your brand?",
            },
        ],
    },
    {
        title: ["UNLEASHING", "VIRALITY"],
        points: [
            {
                point: "Building valuable experiences with positive impact causes people to",
                subText: "spread captivating stories.",
            },
            {
                point: "Sharing captivating stories makes customers feel like the most fascinating person",
                subText: "in their social circle.",
            },
            {
                point: "Creating emotional triggers ignites passionate conversations",
                subText: "that fascinate others.",
            },
        ],
    },
];
