import classNames from "classnames";
import { useEffect, useState } from "react";

enum Direction {
    Down = "down",
    Left = "left",
    Right = "right",
    Up = "up",
}
interface Props {
    hasNext: boolean;
    content: { [key: string]: any };
    isAnimating: boolean;
    isOpen: boolean;
    onClose: () => void;
    onNext: () => void;
    onPrevious: () => void;
}

const InfoModal = ({
    hasNext,
    isAnimating,
    isOpen,
    content,
    onClose,
    onNext,
    onPrevious,
}: Props) => {
    const [isAnimatingBackground, setIsAnimatingBackground] =
        useState<boolean>(false);

    const [animateDirection, setAnimateDirection] = useState<
        Direction | undefined
    >(Direction.Right);

    const handleClick = (action: any) => {
        setIsAnimatingBackground(true);
        setTimeout(() => {
            setIsAnimatingBackground(false);
            setAnimateDirection(undefined);
            action();
        }, 1500);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setAnimateDirection(undefined);
        }, 5000);

        return () => clearTimeout(timeout);
    }, [animateDirection]);

    return (
        <div id="InfoModal" className={classNames({ isModalOpen: isOpen })}>
            <div
                className={classNames("col one", {
                    active: isOpen,
                    activeModal: isOpen,
                    animateToLeft: animateDirection === Direction.Down,
                    animateBackground:
                        isAnimatingBackground &&
                        animateDirection === Direction.Down,
                })}
            >
                <div
                    id="InnerImage"
                    className={classNames("background fade", {
                        activeModal: isOpen,
                        active: isOpen,
                    })}
                    style={{
                        backgroundImage: `url(${
                            !isAnimatingBackground ? content.imageUrl : ""
                        })`,
                    }}
                >
                    <div
                        id="InnerOverlay"
                        className={classNames({
                            animateOverlay: isAnimatingBackground,
                            closeAnimation:
                                animateDirection === Direction.Down ||
                                animateDirection === Direction.Right,
                        })}
                    ></div>
                </div>
            </div>
            <div
                className={classNames("col two background", {
                    active: isOpen,
                    animateBackground: isAnimatingBackground,
                    animateClose: animateDirection === Direction.Down,
                    animateOverlay: animateDirection === Direction.Down,
                    animateToRight: animateDirection === Direction.Down,
                })}
            >
                <div
                    className={classNames({
                        startedOverlay: animateDirection === Direction.Right,
                    })}
                >
                    <p
                        id="Next"
                        className={classNames("btn cn fade", {
                            hidden:
                                !hasNext || animateDirection === Direction.Down,
                        })}
                        onClick={() => {
                            setAnimateDirection(undefined);
                            handleClick(onNext);
                        }}
                    >
                        {" > "}
                    </p>
                    <p
                        id="Prev"
                        className={classNames("btn cn fade", {
                            hidden:
                                hasNext || animateDirection === Direction.Down,
                        })}
                        onClick={() => {
                            setAnimateDirection(undefined);
                            handleClick(onPrevious);
                        }}
                    >
                        {" < "}
                    </p>
                    <p
                        id="Close"
                        className={classNames("btn cn fade", {
                            hidden:
                                hasNext || animateDirection === Direction.Down,
                            disabled: isAnimating,
                        })}
                        style={{ opacity: !hasNext ? 1 : 0 }}
                        onClick={() => {
                            setAnimateDirection(Direction.Down);
                            handleClick(onClose);
                        }}
                    >
                        x
                    </p>
                    <div
                        id="Content"
                        className="fade"
                        style={{
                            top: "calc(50% - 150px)",
                            opacity: isAnimatingBackground ? 0 : 1,
                        }}
                    >
                        {content.children}
                    </div>
                    <img
                        id="Logo"
                        className={classNames("btn fade", {
                            hidden: animateDirection === Direction.Down,
                        })}
                        src="Assets/Icons/logo_2.png"
                        alt="logo"
                    />
                </div>
            </div>
        </div>
    );
};

export default InfoModal;
