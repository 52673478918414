import classNames from "classnames";

const ScrollGif = ({ selectedSection }: { selectedSection: number }) => {
    return (
        <img
            className={classNames({
                active: selectedSection === 4,
                disabled: selectedSection !== 4,
            })}
            id="ScrollGif"
            src="Assets/Video/scroll.gif"
            alt=""
        />
    );
};

export default ScrollGif;
