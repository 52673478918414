import classNames from "classnames";
import { useState } from "react";
import ButtonLearnMore from "../ButtonLearnMore";

interface TeamMemberProps {
    backgroundClassName: string;
    className?: string;
    imageUrl: string;
    linkedin: string;
    name: string;
    role: string;
}

let teamMembers: TeamMemberProps[] = [
    {
        backgroundClassName: "one",
        imageUrl: "Assets/Icons/Social/linkedin.png",
        linkedin: "https://www.linkedin.com/in/nathan-olson-754790b7/",
        name: "NATHAN OLSON",
        role: "PRESIDENT",
    },
    {
        backgroundClassName: "two",
        imageUrl: "Assets/Icons/Social/linkedin.png",
        linkedin: "https://www.linkedin.com/in/philbliss/?originalSubdomain=ca",
        name: "PHIL BLISS",
        role: "MARKETING",
    },
    {
        backgroundClassName: "three",
        imageUrl: "Assets/Icons/Social/linkedin.png",
        linkedin: "https://www.linkedin.com/in/moises-guerra-64620a197/",
        name: "MOISES GUERRA",
        role: "SOCIAL MEDIA",
    },
    {
        backgroundClassName: "four",
        imageUrl: "Assets/Icons/Social/linkedin.png",
        linkedin: "https://www.linkedin.com/in/natasha-green-734273205/",
        name: "NATASHA GREEN",
        role: "OPERATIONS",
    },
    {
        backgroundClassName: "five",
        imageUrl: "Assets/Icons/Social/linkedin.png",
        linkedin: "https://www.linkedin.com/in/johnparis51/",
        name: "JOHN PARIS",
        role: "CONTENT",
    },
    {
        backgroundClassName: "six",
        imageUrl: "Assets/Icons/Social/linkedin.png",
        linkedin: "https://www.linkedin.com/in/jaredsanborn/",
        name: "JARED SANBORN",
        role: "SEO",
    },
    {
        backgroundClassName: "seven",
        imageUrl: "Assets/Icons/Social/linkedin.png",
        linkedin: "https://www.linkedin.com/in/russell-korus-ez",
        name: "RUSSELL KORUS",
        role: "DIGITAL ASSETS",
    },
    {
        backgroundClassName: "eight",
        imageUrl: "Assets/Icons/Social/linkedin.png",
        linkedin: "https://www.linkedin.com/in/dylan-diaz-4764b098",
        name: "DYLAN DIAZ",
        role: "SALES",
    },
    {
        backgroundClassName: "nine",
        imageUrl: "Assets/Icons/Social/linkedin.png",
        linkedin: "https://www.linkedin.com/in/baruchsantana/",
        name: "BARUCH SANTANA",
        role: "SALES",
    },
];

function pickMembers(index: number) {
    const startIndex = (index - 1) * 3;
    const endIndex = startIndex + 3;

    teamMembers = teamMembers.map((el, rowIndx) => {
        el.className = `col${rowIndx}`;

        return el;
    });

    return teamMembers.slice(startIndex, endIndex).map((el, rowIndx) => {
        el.className = `col${rowIndx} active`;

        return el;
    });
}

const TeamMember = ({
    backgroundClassName,
    className,
    imageUrl,
    linkedin,
    name,
    role,
}: TeamMemberProps) => {
    return (
        <div className={`col ${className}`}>
            <div className={`headshot ${backgroundClassName} background`}></div>
            <a href={linkedin} target="_blank" rel="noopener noreferrer">
                <img src={imageUrl} alt="alt" />
            </a>
            <div className="header">
                <h1>
                    <strong>{name}</strong> ({role})
                </h1>
            </div>
        </div>
    );
};

const TeamSection = ({ selectedSection }: { selectedSection: number }) => {
    const [activeIndex, setActiveIndex] = useState(1);
    const [isAnimatingMember, setIsAnimatingMember] = useState<boolean>(false);

    const handleLeftClick = () => {
        setIsAnimatingMember(true);
        setTimeout(() => {
            setActiveIndex(activeIndex > 1 ? activeIndex - 1 : 3);
            setIsAnimatingMember(false);
        }, 1500);
    };

    const handleRightClick = () => {
        setIsAnimatingMember(true);
        setTimeout(() => {
            setActiveIndex(activeIndex < 3 ? activeIndex + 1 : 1);
            setIsAnimatingMember(false);
        }, 1500);
    };

    return (
        <div
            id="TeamSection"
            className={classNames("animatesec cont sec4", {
                active: selectedSection === 4,
                disabled: selectedSection !== 4,
            })}
            data-secname="THE TEAM"
        >
            <div id="Content">
                {pickMembers(activeIndex).map(
                    (teamMember: TeamMemberProps, index: number) => (
                        <TeamMember
                            backgroundClassName={teamMember.backgroundClassName}
                            className={
                                isAnimatingMember
                                    ? ""
                                    : `${teamMember.className}`
                            }
                            imageUrl={teamMember.imageUrl}
                            key={index}
                            linkedin={teamMember.linkedin}
                            name={teamMember.name}
                            role={teamMember.role}
                        />
                    )
                )}

                <div
                    id="Controls"
                    style={{
                        transform: isAnimatingMember
                            ? "rotate(360deg)"
                            : "rotate(0deg)",
                    }}
                >
                    <div className="controls one" onClick={handleLeftClick}>
                        <img alt="left" src="Assets/Icons/left.png" />
                    </div>
                    <div className="controls two" onClick={handleRightClick}>
                        <img alt="right" src="Assets/Icons/right.png" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamSection;
