import classNames from "classnames";

interface Props {
    preloadFinish: boolean;
    isOpen: boolean;
    onClick: () => void;
}

const Hamburger = ({ isOpen, onClick, preloadFinish }: Props) => {
    return (
        <div
            id="Hamburger"
            className={classNames("loadfade", {
                active: isOpen,
                isVisible: preloadFinish,
            })}
            onClick={onClick}
        >
            <div id="Bars" className="inner-el first">
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
            <p id="Close" className="inner-el">
                X
            </p>
        </div>
    );
};

export default Hamburger;
