import "./App.css";

import PreloadNavigate from "./Components/PreloadNavigate";
import LoaderContainer from "./Components/LoaderContainer";
import PreloadNavigateOverlay from "./Components/PreloadNavigateOverlay";
import Hamburger from "./Components/Menu";
import { useCallback, useEffect, useState } from "react";
import NavigationOverlay from "./Components/NavigationOverlay";
import Navigation from "./Components/Navigation";
import CreditSection from "./Components/CreditSection";
import DotNavigation from "./Components/DotNavigation";
import FooterSection from "./Components/FooterSection";
import HowSection from "./Components/HowSection";
import NavLogo from "./Components/NavLogo";
import ScrollBodySection from "./Components/ScrollBodySection";
import ScrollGif from "./Components/ScrollGif";
import SecHeader from "./Components/SecHeader";
import Sliders from "./Components/Sliders";
import StudySection from "./Components/StudySection";
import TeamSection from "./Components/TeamSection";
import WelcomeSection from "./Components/WelcomeSection";
import WhySection from "./Components/WhySection";
import useWheel from "./Components/WheelHandler";
import { SECTIONS_NAME } from "./Constants";

function App() {
    const [isHowModalInfoOpen, setIsHowModalInfoOpen] =
        useState<boolean>(false);
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(false);
    const [isInsideMap, setIsInsideMap] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [preloadFinish, setPreloadFinish] = useState(false);
    const [sectionIndex, setSectionIndex] = useState<number>(1);
    const [wheelDirection, setWheelDirection] = useState<"up" | "down">();

    const handleMenuClick = useCallback(() => {
        setIsModalOpen(!isModalOpen);
    }, [isModalOpen]);

    const handleNavClick = useCallback((index: number) => {
        setTimeout(() => {
            setSectionIndex((prev) => {
                prev < index
                    ? setWheelDirection("up")
                    : setWheelDirection("down");
                return index;
            });
        }, 1000);
        setIsModalOpen(false);
    }, []);

    useEffect(() => {
        setIsInitialLoad(true);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setPreloadFinish(true);
        }, 3000);
    }, []);

    useWheel({
        isHowModalInfoOpen,
        isInitialLoad,
        isInsideMap,
        sectionIndex,
        setSectionIndex,
        wheelDirection,
    });

    return (
        <div className="container">
            <ScrollBodySection />
            <Navigation isOpen={isModalOpen} handleNavClick={handleNavClick} />
            <NavigationOverlay isOpen={isModalOpen} />
            <PreloadNavigate preloadFinish={preloadFinish} />
            <LoaderContainer preloadFinish={preloadFinish} />
            <PreloadNavigateOverlay preloadFinish={preloadFinish} />
            <Hamburger
                isOpen={isModalOpen}
                preloadFinish={preloadFinish}
                onClick={handleMenuClick}
            />
            <Sliders />
            <NavLogo />
            <DotNavigation
                activeDot={sectionIndex}
                onDotClick={handleNavClick}
                preloadFinish={preloadFinish}
            />
            <ScrollGif selectedSection={sectionIndex} />
            <SecHeader text={SECTIONS_NAME[sectionIndex]} />
            <WelcomeSection selectedSection={sectionIndex} />
            <HowSection
                isHowModalOpen={isHowModalInfoOpen}
                selectedSection={sectionIndex}
                openModal={() => {
                    setIsHowModalInfoOpen(true);
                }}
                closeModal={() => {
                    setIsHowModalInfoOpen(false);
                }}
            />
            <WhySection selectedSection={sectionIndex} />
            <TeamSection selectedSection={sectionIndex} />
            <StudySection selectedSection={sectionIndex} />
            <CreditSection selectedSection={sectionIndex} />
            <FooterSection
                selectedSection={sectionIndex}
                setIsInsideMap={setIsInsideMap}
            />
        </div>
    );
}

export default App;
