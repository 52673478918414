import classNames from "classnames";

interface Props {
    isOpen: boolean;
}

const NavigationOverlay = ({ isOpen }: Props) => {
    return (
        <div
            id="NavigationOverlay"
            className={classNames({ isModalOpen: isOpen })}
        ></div>
    );
};

export default NavigationOverlay;
