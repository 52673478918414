interface ButtonLearnMoreProps {
    learnMoreLink: string;
    id: string;
    className: string;
    text: string;
}

const ButtonLearnMore = ({
    learnMoreLink,
    id,
    className,
    text
}: ButtonLearnMoreProps) => {
    return (
        <div id={id}>
            <div className="col cont but">
                <div className={className}>
                    <a href={learnMoreLink} target="_blank" rel="noreferrer">
                        {text}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ButtonLearnMore;
