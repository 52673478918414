import classNames from "classnames";

const LoaderContainer = ({ preloadFinish }: { preloadFinish: boolean }) => {
    return (
        <div
            id="LoaderContainer"
            className={classNames({ hideLoader: preloadFinish })}
        >
            <div id="LoaderInner">
                <div id="SpinnerOne" className="spin"></div>
                <div id="SpinnerTwo" className="spin"></div>
                <div id="SpinnerThree" className="spin"></div>
                <div id="SpinnerFour" className="spin"></div>
            </div>
        </div>
    );
};

export default LoaderContainer;
