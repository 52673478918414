import classNames from "classnames";
import { useState, useEffect, useCallback } from "react";

const studyItems = [
    {
        backgroundImage: "Assets/Images/Study/1.webp",
        caseStudyLink:
            "https://puremarketing.agilecrm.com/forms/6677529735004160",
        className: "background",
        description: "BUILDING LOCAL AUTHORITY FOR A TALENTED ARTIST",
        id: "Img1",
        title: "MAC SMP",
    },
    {
        backgroundImage: "Assets/Images/Study/2.webp",
        caseStudyLink:
            "https://puremarketing.agilecrm.com/forms/6677529735004160",
        className: "background",
        description: "DEVELOPING BRAND LANGUAGE THAT FASCINATES PEOPLE",
        id: "Img2",
        title: "RELATUS",
    },
    {
        backgroundImage: "Assets/Images/Study/3.webp",
        caseStudyLink:
            "https://puremarketing.agilecrm.com/forms/6677529735004160",
        className: "background",
        description: "STRENGTHENING PERSONAL BRAND RELATIONSHIPS",
        id: "Img3",
        title: "SOPHIA THE ROBOT",
    },
    {
        backgroundImage: "Assets/Images/Study/4.webp",
        caseStudyLink:
            "https://puremarketing.agilecrm.com/forms/6677529735004160",
        className: "background",
        description: "BRINGING INNOVATIVE IDEAS TO A HISTORIC INDUSTRY",
        id: "Img4",
        title: "TREASURE WRECK",
    },
];

interface StudyItemProps {
    backgroundImage: string;
    caseStudyLink: string;
    className: string;
    description: string;
    id: string;
    title: string;
}

const StudyItem = ({
    backgroundImage,
    caseStudyLink,
    className,
    description,
    id,
    title,
}: StudyItemProps) => {
    return (
        <div
            id={id}
            className={`col sc-el ${className}`}
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="scinner sc-el">
                <div className="sccont">
                    <h1>{title}</h1>
                    <p>{description}</p>
                    <a href={caseStudyLink} target="_blank" rel="noreferrer">
                        <h2>VIEW CASE STUDY</h2>
                    </a>
                </div>
            </div>
        </div>
    );
};

const StudySection = ({ selectedSection }: { selectedSection: number }) => {
    const [currentIndex, setCurrentIndex] = useState(1);
    const [done, setDone] = useState(true);

    const handleClick = useCallback(
        (isPrev: boolean) => {
            if (done) {
                let newIndex: number;

                if (!isPrev && currentIndex + 1 < 5) {
                    newIndex = currentIndex + 1;
                } else if (isPrev) {
                    if (currentIndex > 1) {
                        newIndex = currentIndex - 1;
                    } else {
                        newIndex = 4;
                    }
                } else {
                    newIndex = 1;
                }

                const activeSection = $(`#Img${currentIndex}.col.active`);
                const targetSection = $(`#Img${newIndex}`);

                animateSections(activeSection, targetSection, newIndex);

                setDone(false);
                setTimeout(() => {
                    setDone(true);
                    setCurrentIndex(newIndex);
                }, 600);
            }
        },
        [currentIndex, done]
    );

    const autoSlide = useCallback(() => {
        if (currentIndex + 1 < 5) {
            setCurrentIndex(currentIndex + 1);
            handleClick(false);
        } else {
            setCurrentIndex(0);
            handleClick(false);
        }
    }, [currentIndex, handleClick]);

    useEffect(() => {
        const intervalId = setInterval(autoSlide, 3000);

        return () => {
            clearInterval(intervalId);
        };
    }, [autoSlide]);

    const animateSections = (
        activeSection: JQuery<HTMLElement>,
        targetSection: JQuery<HTMLElement>,
        index: number
    ) => {
        activeSection.animate(
            { left: "100%" },
            200,
            "easeOutQuad",
            function () {
                $(this).removeClass("active");
            }
        );

        targetSection
            .addClass("active")
            .css({ left: -index * 100 + "%" })
            .animate({ left: "0%" }, 200, "easeOutQuad");
    };

    return (
        <div
            id="StudySection"
            className={classNames("animatesec cont sec5", {
                active: selectedSection === 5,
                disabled: selectedSection !== 5,
            })}
            data-secname="THE WHAT"
        >
            <div id="StudyCarousel" className="sc-el">
                <div id="StudyCarouselContainer" className="sc-el">
                    {studyItems.map(
                        ({
                            backgroundImage,
                            caseStudyLink,
                            className,
                            description,
                            id,
                            title,
                        }: StudyItemProps) => (
                            <StudyItem
                                backgroundImage={backgroundImage}
                                caseStudyLink={caseStudyLink}
                                className={className}
                                description={description}
                                id={id}
                                key={id}
                                title={title}
                            />
                        )
                    )}
                </div>

                <div
                    id="StudyCarouselOverlay"
                    data-carousel="StudyCarousel"
                    data-maxindex="4"
                    data-currentindex="1"
                >
                    <img
                        id="Left"
                        className="control"
                        src="Assets/Icons/left_2.png"
                        onClick={() => handleClick(true)}
                        alt="left"
                    />
                    <img
                        id="Right"
                        className="control"
                        src="Assets/Icons/right_2.png"
                        onClick={() => handleClick(false)}
                        alt="right"
                    />
                </div>
            </div>
        </div>
    );
};

export default StudySection;
