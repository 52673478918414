const Sliders: React.FC = () => {
    return (
        <>
            <div id="SecNavOne" className="animatesec slider"></div>
            <div id="SecNavTwo" className="animatesec slider"></div>
        </>
    );
};

export default Sliders;
