import classNames from "classnames";
import Typewriter from "typewriter-effect";
import ButtonLearnMore from "../ButtonLearnMore";

const WelcomeSection = ({ selectedSection }: { selectedSection: number }) => {
    return (
        <div
            id="WelcomeSection"
            className={classNames("animatesec cont sec1", {
                active: selectedSection === 1,
                disabled: selectedSection !== 1,
            })}
            data-secname="WELCOME"
        >
            <div
                id="WelcomeImage"
                className="fill-parent layout background"
            ></div>
            <div id="WelcomeOverlay" className="fill-parent layout">
                <h1>
                    WE UNLOCK{" "}
                    <Typewriter
                        options={{
                            strings: [
                                "FASCINATION",
                                "TRANSCENDENCE",
                                "VIRALITY",
                            ],
                            autoStart: true,
                            loop: true,
                            cursorClassName: "typewritercursor",
                        }}
                    />
                    <br />
                    <span className="two">FOR BRANDS / INFLUENCERS / YOU</span>
                    <br />
                    <span className="Button">
                        <ButtonLearnMore
                            learnMoreLink="https://calendly.com/puremarketinggroup/30min/"
                            id="Button"
                            className="inner"
                            text="UNLOCK"
                        />
                    </span>
                </h1>
            </div>
        </div>
    );
};

export default WelcomeSection;