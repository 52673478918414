import { useCallback, useEffect, useState, useTransition } from "react";

interface Props {
    isHowModalInfoOpen: boolean;
    isInsideMap: boolean;
    isInitialLoad: boolean;
    sectionIndex: number;
    setSectionIndex: (index: number) => void;
    wheelDirection?: "up" | "down";
}

function whySectionAnimation(refresh?: boolean) {
    const counter = $(".stat");
    if (refresh) {
        counter.removeClass("active");
        return;
    }

    if (!counter.hasClass("active")) {
        counter.addClass("active");
        counter.each(function () {
            const $this = $(this);
            const countText = $this.attr("data-count");

            $({ value: 0 }).animate(
                { value: countText },
                {
                    duration: 1000,
                    step: function (this: { value: number }) {
                        $this.text(Math.round(this.value));
                    },
                }
            );
        });
    }
}

const useWheel = ({
    isHowModalInfoOpen,
    isInsideMap,
    isInitialLoad,
    sectionIndex,
    setSectionIndex,
    wheelDirection,
}: Props) => {
    const [currentWheelDirection, setCurrentWheelDirection] = useState<
        "up" | "down"
    >(wheelDirection!);

    const [isWheeling, setIsWheeling] = useState<boolean>(false);
    const [prevScrollPos, setPrevScrollPos] = useState<number>(0);
    const [isPending, startTransition] = useTransition();

    const animateSection = useCallback(() => {
        const animateSection = ".animatesec.slider";
        const targetSection = `.sec${sectionIndex}`;
        const previousSection = `.sec${
            currentWheelDirection === "down"
                ? sectionIndex - 1
                : sectionIndex + 1
        }`;
        const headerSection = "#SecHeader";

        const sectionId = $(targetSection).attr("data-secname");

        if (sectionId && isInitialLoad && !isWheeling) {
            setIsWheeling(true);

            let animationOffset: number;

            currentWheelDirection === "down"
                ? (animationOffset = 100)
                : (animationOffset = -100);

            $(animateSection).css({ top: `${animationOffset}vh` });
            $(targetSection).css({ top: `${animationOffset}vh` });

            $(animateSection).each(function (i: number) {
                $(this)
                    .delay(i * 300)
                    .animate(
                        { top: `${animationOffset * -1}vh` },
                        1000,
                        function () {
                            $(animateSection).removeAttr("style");
                            $(targetSection).animate(
                                { top: `0px` },
                                500,
                                function () {}
                            );
                            $(previousSection).removeAttr("style");
                            let i = 1;
                            const previousIndex =
                                currentWheelDirection === "down"
                                    ? sectionIndex - 1
                                    : sectionIndex + 1;
                            while (i < 8) {
                                if (i !== previousIndex) {
                                    $(`.sec${i}`).removeAttr("style");
                                }
                                i++;
                            }
                        }
                    );
            });

            if (sectionIndex === 3) {
                $(".col.stats").animate({ opacity: "1" }, 1000, function () {
                    whySectionAnimation();
                });
            } else {
                whySectionAnimation(true);
            }

            $(headerSection).animate({ opacity: "0" }, 1000, function () {
                $(headerSection)
                    .removeAttr("style")
                    .attr("data-secname", sectionId!)
                    .animate({ opacity: "1" }, 500);
            });

            setTimeout(() => {
                setIsWheeling(false);
            }, 3000);
        }
    }, [sectionIndex]);

    const handleScroll = useCallback(
        (event: any) => {
            if (isWheeling) {
                event.preventDefault();
                event.stopPropagation();
            }
            if (!isPending) {
                startTransition(() => {
                    if (isInsideMap) {
                        return;
                    }
                    const currentScrollPos = window.pageYOffset;
                    if (
                        currentScrollPos > prevScrollPos &&
                        !isWheeling &&
                        !isHowModalInfoOpen
                    ) {
                        if (sectionIndex < 7) {
                            if (!currentWheelDirection) {
                                setCurrentWheelDirection("down");
                            }
                            setTimeout(() => {
                                setSectionIndex(sectionIndex + 1);
                            }, 500);
                        }
                    } else if (
                        currentScrollPos < prevScrollPos &&
                        !isWheeling &&
                        !isHowModalInfoOpen
                    ) {
                        if (sectionIndex > 1) {
                            if (!currentWheelDirection) {
                                setCurrentWheelDirection("up");
                            }
                            setTimeout(() => {
                                setSectionIndex(sectionIndex - 1);
                            }, 500);
                        }
                    }
                    setPrevScrollPos(currentScrollPos);
                });
            }
        },
        [
            currentWheelDirection,
            isHowModalInfoOpen,
            isInsideMap,
            isPending,
            isWheeling,
            prevScrollPos,
            sectionIndex,
            setSectionIndex,
        ]
    );

    const handleWheel = useCallback(
        (event: any) => {
            if (isWheeling) {
                event.preventDefault();
                event.stopPropagation();
            }
            if (!isPending) {
                startTransition(() => {
                    if (isInsideMap) {
                        return;
                    }
                    if (
                        event.deltaY > 0 &&
                        !isWheeling &&
                        !isHowModalInfoOpen
                    ) {
                        if (sectionIndex < 7) {
                            setCurrentWheelDirection("down");
                            setTimeout(() => {
                                setSectionIndex(sectionIndex + 1);
                            }, 500);
                        }
                    } else if (
                        event.deltaY < 0 &&
                        !isWheeling &&
                        !isHowModalInfoOpen
                    ) {
                        if (sectionIndex > 1) {
                            setCurrentWheelDirection("up");
                            setTimeout(() => {
                                setSectionIndex(sectionIndex - 1);
                            }, 500);
                        }
                    }
                });
            }
        },
        [
            isHowModalInfoOpen,
            isInsideMap,
            isPending,
            isWheeling,
            sectionIndex,
            setSectionIndex,
        ]
    );

    useEffect(() => {
        animateSection();
    }, [animateSection]);

    useEffect(() => {
        window.addEventListener("wheel", handleWheel, { passive: false });
        if ("ontouchstart" in window) {
            window.addEventListener("scroll", handleScroll, { passive: false });
        }
        return () => {
            window.removeEventListener("wheel", handleWheel);
            if ("ontouchstart" in window) {
                window.removeEventListener("scroll", handleScroll);
            }
        };
    }, [handleScroll, handleWheel]);

    useEffect(() => {
        setCurrentWheelDirection(wheelDirection!);
    }, [wheelDirection]);
};

export default useWheel;
