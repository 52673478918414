import classNames from "classnames";
import InfoModal from "../InfoModal";
import { useEffect, useState } from "react";
import {
    HOW_CONTENTS_PART_1,
    HOW_CONTENTS_PART_2,
    HOW_CONTENTS_PART_3,
} from "../../Constants";
import ButtonLearnMore from "../ButtonLearnMore";

interface Props {
    closeModal: () => void;
    isHowModalOpen: boolean;
    openModal: () => void;
    selectedSection: number;
}

interface IPartContent {
    title: string[];
    points: {
        point: string;
        subText: string;
    }[];
}

const PartContent = ({ title, points }: IPartContent) => (
    <div key={title[0]}>
        <h1>
            <strong>{title[0]}</strong>
            <br />
            {title[1]}
        </h1>
        <span className="divider"></span>
        {points.map(({ point, subText }, index2) => (
            <p key={point} className={classNames({ first: index2 === 0 })}>
                <span>{index2 + 1}.</span>
                <strong>{point}</strong> &nbsp; {subText}
            </p>
        ))}
    </div>
);

const howContent: { [key: number]: { [key: number]: { [key: string]: any } } } =
    {
        1: {
            1: {
                imageUrl: "Assets/Images/How/1.png",
                children: (
                    <>
                        {HOW_CONTENTS_PART_1.map(
                            ({ title, points }, index) =>
                                index === 0 && (
                                    <><PartContent
                                        key={title[0]}
                                        title={title}
                                        points={points} /><ButtonLearnMore
                                            learnMoreLink="https://calendly.com/puremarketinggroup/30min/"
                                            id="Button"
                                            className="inner"
                                            text="Discovery Call" /></>
                                )
                        )}
                    </>
                ),
            },
            2: {
                imageUrl: "Assets/Images/How/2.png",
                children: (
                    <>
                        {HOW_CONTENTS_PART_1.map(
                            ({ title, points }, index) =>
                                index === 1 && (
                                    <><PartContent
                                        key={title[0]}
                                        title={title}
                                        points={points} /><ButtonLearnMore
                                            learnMoreLink="https://calendly.com/puremarketinggroup/30min/"
                                            id="Button"
                                            className="inner"
                                            text="Discovery Call" /></>
                                )
                        )}
                    </>
                ),
            },
        },
        2: {
            1: {
                imageUrl: "Assets/Images/How/3.png",
                children: (
                    <>
                        {HOW_CONTENTS_PART_2.map(
                            ({ title, points }, index) =>
                                index === 0 && (
                                    <><PartContent
                                        key={title[0]}
                                        title={title}
                                        points={points} /><ButtonLearnMore
                                            learnMoreLink="https://calendly.com/puremarketinggroup/30min/"
                                            id="Button"
                                            className="inner"
                                            text="Discovery Call" /></>
                                )
                        )}
                    </>
                ),
            },
            2: {
                imageUrl: "Assets/Images/How/4.png",
                children: (
                    <>
                        {HOW_CONTENTS_PART_2.map(
                            ({ title, points }, index) =>
                                index === 1 && (
                                    <><PartContent
                                        key={title[0]}
                                        title={title}
                                        points={points} /><ButtonLearnMore
                                            learnMoreLink="https://calendly.com/puremarketinggroup/30min/"
                                            id="Button"
                                            className="inner"
                                            text="Discovery Call" /></>
                                )
                        )}
                    </>
                ),
            },
        },
        3: {
            1: {
                imageUrl: "Assets/Images/How/5.png",
                children: (
                    <>
                        {HOW_CONTENTS_PART_3.map(
                            ({ title, points }, index) =>
                                index === 0 && (
                                    <><PartContent
                                        key={title[0]}
                                        title={title}
                                        points={points} /><ButtonLearnMore
                                            learnMoreLink="https://calendly.com/puremarketinggroup/30min/"
                                            id="Button"
                                            className="inner"
                                            text="Discovery Call" /></>
                                )
                        )}
                    </>
                ),
            },
            2: {
                imageUrl: "Assets/Images/How/6.png",
                children: (
                    <>
                        {HOW_CONTENTS_PART_3.map(
                            ({ title, points }, index) =>
                                index === 1 && (
                                    <><PartContent
                                        key={title[0]}
                                        title={title}
                                        points={points} /><ButtonLearnMore
                                            learnMoreLink="https://calendly.com/puremarketinggroup/30min/"
                                            id="Button"
                                            className="inner"
                                            text="Discovery Call" /></>
                                )
                        )}
                    </>
                ),
            },
        },
    };

const HowSection = ({
    closeModal,
    isHowModalOpen,
    openModal,
    selectedSection,
}: Props) => {
    const [selectedOption, setSelectedOption] = useState<number>(1);
    const [selectedPart, setSelectedPart] = useState<number>(1);
    const [isAnimating, setIsAnimating] = useState<boolean>(false);

    const animate = () => {
        setTimeout(() => {
            $("#Content").css({ opacity: 1 });
            $("#InnerImage").css({ opacity: 1 });
            $("#Logo").css({ opacity: 1 });
            $("#Next").css({ opacity: 1 });
            $("#Prev").css({ opacity: 1 });
        }, 1000);
    };

    const onClose = () => {
        closeModal();
    };

    const onNext = () => {
        if (selectedPart < 2) {
            setIsAnimating(true);
            setSelectedPart(selectedPart + 1);
            animate();
        }
    };

    const onPrevious = () => {
        if (selectedPart > 1) {
            setSelectedPart(selectedPart - 1);
            animate();
        }
    };

    const handleOptionClicked = (index: number) => {
        setSelectedOption(index);
        setSelectedPart(1);
        openModal();
    };

    useEffect(() => {
        setTimeout(() => {
            setIsAnimating(false);
        }, 1500);
    }, [isAnimating]);

    return (
        <>
            <InfoModal
                content={howContent[selectedOption][selectedPart]}
                hasNext={selectedPart === 1}
                isAnimating={isAnimating}
                isOpen={isHowModalOpen}
                onClose={onClose}
                onNext={onNext}
                onPrevious={onPrevious}
            />

            <div
                id="HowSection"
                className={classNames("animatesec cont sec2", {
                    active: selectedSection === 2,
                    disabled: selectedSection !== 2,
                })}
                data-secname="THE HOW"
            >
                <div
                    id="HowImage"
                    className="fill-parent layout background"
                ></div>
                <div id="HowOverlay" className="fill-parent layout">
                    <div
                        className="col a"
                        onClick={() => handleOptionClicked(1)}
                    >
                        <div className="hoverdiv"></div>
                        <h1>
                            UNLOCKING
                            <br />
                            FASCINATION
                        </h1>
                    </div>
                    <div
                        className="col b"
                        onClick={() => handleOptionClicked(2)}
                    >
                        <div className="hoverdiv"></div>
                        <h1>
                            UNLOCKING
                            <br />
                            TRANSCENDENCE
                        </h1>
                    </div>
                    <div
                        className="col c"
                        onClick={() => handleOptionClicked(3)}
                    >
                        <div className="hoverdiv"></div>
                        <h1>
                            UNLOCKING
                            <br />
                            VIRALITY
                        </h1>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HowSection;
