import classNames from "classnames";

const PreloadNavigateOverlay = ({
    preloadFinish,
}: {
    preloadFinish: boolean;
}) => {
    return (
        <div
            id="PreloadNavigateOverlay"
            className={classNames({ hideLoader: preloadFinish })}
        ></div>
    );
};

export default PreloadNavigateOverlay;
