import classNames from "classnames";
import Typewriter from "typewriter-effect";

interface Props {
    selectedSection: number;
}

const WhySection = ({ selectedSection }: Props) => {
    return (
        <div
            id="WhySection"
            className={classNames("animatesec cont sec3", {
                active: selectedSection === 3,
                disabled: selectedSection !== 3,
            })}
            data-secname="WELCOME"
        >
            <div className="col stats">
                <div className="statrow">
                    <div className="innercol one">
                        <h1>
                            <span className="stat" data-count="10"></span>+
                        </h1>
                        <p> YEARS IN BUSINESS </p>
                    </div>

                    <div className="innercol two">
                        <h1>
                            <span className="stat" data-count="70"></span>M+
                        </h1>
                        <p> FOLLOWERS GAINED </p>
                    </div>

                    <div className="statdivider"></div>

                    <div className="innercol three">
                        <h1>
                            <span className="stat" data-count="2.5"></span>M+
                        </h1>
                        <p> LEADS GENERATED </p>
                    </div>

                    <div className="innercol four">
                        <h1>
                            <span className="stat" data-count="3.4"></span>K+
                        </h1>
                        <p> HAPPY CUSTOMERS </p>
                    </div>
                </div>
            </div>

            <div className="col clients">
                <div className="clientimg background"></div>
                <div className="clientrow">
                    <h1>
                        OUR{" "}
                        <Typewriter
                            options={{
                                strings: ["CLIENTS"],
                                autoStart: true,
                                loop: true,
                                cursorClassName: "typewritercursor",
                            }}
                        />
                    </h1>
                    <p>
                        ACCELERATING INDUSTRY LEADERS AND
                        <br />
                        AGILE STARTUPS THROUGHOUT 20+ SECTORS
                    </p>
                </div>
            </div>
        </div>
    );
};

export default WhySection;
