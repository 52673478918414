import classnames from "classnames";

interface IDotNavigationProps {
    preloadFinish: boolean;
    activeDot?: number;
    onDotClick: (index: number) => void;
}

const DotNavigation = ({
    activeDot = 1,
    onDotClick,
    preloadFinish,
}: IDotNavigationProps) => {
    return (
        <div
            id="DotNavigation"
            className={classnames("loadfade", { isVisible: preloadFinish })}
        >
            <div
                id="One"
                className={classnames(
                    "hoptosection hop1 container dotnav smoothscroll",
                    { active: activeDot === 1 }
                )}
                onClick={() => onDotClick(1)}
            >
                <div className="inner"></div>
            </div>
            <div
                id="Two"
                className={classnames(
                    "hoptosection hop1 container dotnav smoothscroll",
                    { active: activeDot === 2 }
                )}
                onClick={() => onDotClick(2)}
            >
                <div className="inner"></div>
            </div>
            <div
                id="Three"
                className={classnames(
                    "hoptosection hop1 container dotnav smoothscroll",
                    { active: activeDot === 3 }
                )}
                onClick={() => onDotClick(3)}
            >
                <div className="inner"></div>
            </div>
            <div
                id="Four"
                className={classnames(
                    "hoptosection hop1 container dotnav smoothscroll",
                    { active: activeDot === 4 }
                )}
                onClick={() => onDotClick(4)}
            >
                <div className="inner"></div>
            </div>
            <div
                id="Five"
                className={classnames(
                    "hoptosection hop1 container dotnav smoothscroll",
                    { active: activeDot === 5 }
                )}
                onClick={() => onDotClick(5)}
            >
                <div className="inner"></div>
            </div>
            <div
                id="Six"
                className={classnames(
                    "hoptosection hop1 container dotnav smoothscroll",
                    { active: activeDot === 6 }
                )}
                onClick={() => onDotClick(6)}
            >
                <div className="inner"></div>
            </div>
            <div
                id="Seven"
                className={classnames(
                    "hoptosection hop1 container dotnav smoothscroll",
                    { active: activeDot === 7 }
                )}
                onClick={() => onDotClick(7)}
            >
                <div className="inner"></div>
            </div>
        </div>
    );
};

export default DotNavigation;
