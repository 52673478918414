import classNames from "classnames";
import { SECTIONS_NAME } from "../../Constants";

interface Props {
    isOpen: boolean;
    handleNavClick: (index: number) => void;
}

const Navigation = ({ handleNavClick, isOpen }: Props) => {
    return (
        <div id="Navigation" className={classNames({ isModalOpen: isOpen })}>
            <div id="NavigationInner">
                <div className="links one">
                    <p className="head"> NAV LINKS </p>
                    {Object.values(SECTIONS_NAME).map((el, index) =>
                        index < 3 ? (
                            <p
                                key={el}
                                className={`hoptosection ${
                                    "hop" + (index + 1)
                                } nav`}
                                onClick={() => handleNavClick(index + 1)}
                            >
                                {el}
                            </p>
                        ) : null
                    )}
                </div>

                <div className="links two">
                    {Object.values(SECTIONS_NAME).map((el, index) =>
                        index >= 3 ? (
                            <p
                                key={el}
                                className={`hoptosection ${
                                    "hop" + (index + 1)
                                } nav`}
                                onClick={() => handleNavClick(index + 1)}
                            >
                                {index + 1 !== 6 ? el : "AGENCY CREDIT"}
                            </p>
                        ) : null
                    )}
                </div>
            </div>

            <div id="Social" className={classNames({ active: isOpen })}>
                <a
                    id="IG"
                    href="https://www.instagram.com/puremarketing.ai/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        src="Assets/Icons/Social/instagram.png"
                        alt="instagram"
                    />
                </a>
                <a
                    id="FB"
                    href="https://www.facebook.com/PureMarketingAI"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        src="Assets/Icons/Social/facebook.png"
                        alt="facebook"
                    />
                </a>
            </div>
        </div>
    );
};

export default Navigation;
