import classNames from "classnames";
import Typewriter from "typewriter-effect";

interface CreditSectionProps {
    selectedSection: number;
}

const CreditSection = ({ selectedSection }: CreditSectionProps) => {
    return (
        <div
            id="CreditSection"
            className={classNames("animatesec cont sec6", {
                active: selectedSection === 6,
                disabled: selectedSection !== 6,
            })}
            data-secname="PMG CREDIT"
        >
            <div className="col img background">
                <div className="overlay">
                    <img src="Assets/Icons/credit.png" alt="Credit Icon" />
                </div>
            </div>
            <div className="col cont">
                <div className="inner">
                    <h1>
                        AGENCY{" "}
                        <strong>
                            <span id="TypewriterTextThree">
                                <Typewriter
                                    options={{
                                        strings: ["CREDITS"],
                                        autoStart: true,
                                        loop: true,
                                        cursorClassName: "typewritercursor",
                                    }}
                                />
                            </span>
                        </strong>
                    </h1>
                    <div className="divider"></div>
                    <h2>
                        Pure Marketing measures time in Agency Hours. Products
                        and Deliverables are calculated by the estimated time to
                        complete in hours. We allocate resources to your account
                        based on this calculation.
                        <br />
                        <br /> Agency Hours can be converted 1:3 (1 Agency Hr is
                        worth 3 Working Hrs). 1 PMG expert can work for 3 hrs or
                        3 PMG experts can work for 1 hr.
                    </h2>
                    <a
                        href="https://calendly.com/puremarketinggroup/30min/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        LEARN MORE
                    </a>
                </div>
            </div>
        </div>
    );
};

export default CreditSection;
